import type { CookieValueTypes } from 'cookies-next';
import { getCookie } from 'cookies-next';
import { jwtDecode } from 'jwt-decode';
import type { PianoUser, PianoCookie } from 'types/piano';
import { PIANO_COOKIE_NAME } from 'lib/middleware/constants';

export const getPianoCookieClient = (): Nullable<PianoUser> => {
  const pianoCookie = getCookie(PIANO_COOKIE_NAME) as CookieValueTypes;

  if (!pianoCookie) return null;

  const decodedPianoCookie = jwtDecode<PianoCookie>(pianoCookie);

  return mapToPianoUser(decodedPianoCookie);
};

const mapToPianoUser = (input: PianoCookie): PianoUser => ({
  ...input,
  uuid: input?.social_uuid ?? input?.uuid
});
