import { useUserProfile } from 'hooks/useIdentifyAnalytics/helpers';
import { getCookie } from 'cookies-next';
import { RIVT_TOKEN_COOKIE_NAME } from 'lib/middleware/constants';
import { ExtendedRawUserProfile } from 'hooks/useIdentifyAnalytics/types';

import { getUserData } from './getUserData';

export const useOutsidePackagesUserData = (
  placeholderData?: ExtendedRawUserProfile
) => {
  const rivtToken = getCookie(RIVT_TOKEN_COOKIE_NAME);
  const { data: RIVTProfile, isFetching } = useUserProfile(
    rivtToken,
    placeholderData
  );

  const userData = getUserData(RIVTProfile);

  return { isFetching, userData };
};
