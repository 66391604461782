import { DefaultSeo } from 'next-seo';
import '@pocketoutdoormedia/web-microfrontends.styles.global-styles/src/_index.css';
import { AppContext, AppInitialProps } from 'next/app';
import Footer from 'components/03-organisms/Footer';
import { SITE_NAME } from 'lib/constants';
import { InitDatadog } from 'helpers/init-datadog';
import { useRouter } from 'next/router';
import Scripts from 'scripts';
import {
  authServiceFactory,
  AuthServiceProvider
} from '@pocketoutdoormedia/auth-state-service';
// Doesn't have to be SASS, just ensure the CSS is included.
import 'priority-plus/dist/priority-plus.css';
import 'styles/globals.css';
import { LearnHeadlessProvider } from 'components/04-templates/LearnHeadlessProvider/LearnHeadlessProvider';
import SubNavigation from 'components/03-organisms/SubNavigation';
import { IdentifyAnalyticsService } from 'hooks/useIdentifyAnalytics';
import Header from 'components/03-organisms/Header';
import { ToastContainer } from 'components/01-atoms/Toast';
import { QueryClient, QueryClientProvider } from 'react-query';

import datadogKeys from '../datadog.config';

if (process.env.NODE_ENV === 'production') {
  InitDatadog(datadogKeys);
}

type OutsideLearnProps = AppContext & AppInitialProps;

const OutsideLearn = ({ Component, pageProps }: OutsideLearnProps) => {
  const { asPath } = useRouter();
  const authService = authServiceFactory();
  const seoProps = {
    titleTemplate: '%s | Outside Learn',
    defaultTitle: 'Outside Learn',
    description:
      "Learn from the best with Outside's online education series. Whether you are a beginner or an expert who wants to level up, Outside Learn has you covered.",
    openGraph: {
      url: `https://learn.outsideonline.com${asPath}`,
      title: 'Outside Learn',
      description:
        "Learn from the best with Outside's online education series. Whether you are a beginner or an expert who wants to level up, Outside Learn has you covered.",
      site_name: 'Outside Learn',
      type: 'webpage',
      locale: 'en_US'
    },
    twitter: {
      cardType: 'summary_large_image'
    }
  };

  const client = new QueryClient();

  return (
    <QueryClientProvider client={client}>
      <AuthServiceProvider value={authService}>
        <LearnHeadlessProvider domain={SITE_NAME}>
          <IdentifyAnalyticsService />
          <DefaultSeo {...seoProps} />
          <Header />
          <SubNavigation />
          <ToastContainer />
          <Component {...pageProps} />
          <div className="mt-20">
            <Footer />
          </div>
        </LearnHeadlessProvider>
        <Scripts />
      </AuthServiceProvider>
    </QueryClientProvider>
  );
};

export default OutsideLearn;
