import { datadogRum } from '@datadog/browser-rum';

export type InitDatadogProps = {
  /** The RUM application ID. */
  appId: string;
  /** A Datadog client token. */
  clientToken: string;
  /** The service name for your application. */
  service: string;
  /** The application’s environment DEFAULT: production */
  env?: string;
  /** The percentage of sessions to track: 100 for all, 0 for none. DEFAULT: 100 */
  sampleRate?: number;
  /** Enables automatic collection of users actions. DEFAULT: true */
  trackInteractions?: boolean;
  /** Session Replay settings DEFAULT: 'mask-user-input' */
  defaultPrivacyLevel?: any;
};

export function InitDatadog({
  appId,
  clientToken,
  service,
  env = 'production',
  sampleRate = 100,
  trackInteractions = true,
  defaultPrivacyLevel = 'mask-user-input'
}: InitDatadogProps) {
  datadogRum.init({
    applicationId: appId,
    clientToken,
    site: 'datadoghq.com',
    service,
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate,
    trackInteractions,
    defaultPrivacyLevel
  });
  datadogRum.startSessionReplayRecording();
}
