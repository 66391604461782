import type { Variants } from '@amplitude/experiment-js-client';

import type { FeatureFlags, FlagKey } from '../types';

export const parseFeatureFlags = (flags: Variants) =>
  (Object.keys(flags) as FlagKey[]).reduce((acc, key) => {
    acc[key] = flags[key].value === 'on';

    return acc;
  }, {} as FeatureFlags);
