import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';
import {
  PRIVACI_COOKIE_CONSENT_GENERATED,
  PRIVACI_COOKIE_CONSENT_UUID,
  PRIVACI_COOKIE_CONSENTS
} from 'lib/constants';
import { FEATURE_FLAGS } from 'lib/amplitude/constants';
import { useAmplitudeFlags } from 'helpers/hooks/useAmplitudeFlags';

const IS_DEV = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

export const showEmailVerificationWarning = async () => {
  // We must first import this package so that the email-verify-warning-notification package
  // works properly because it depends on the main toast package
  await import('@pocketoutdoormedia/toast');
  const { VerifyEmailToasts } = await import(
    '@pocketoutdoormedia/email-verify-notifications'
  );

  const verifyEmailToastsInstance = VerifyEmailToasts.getInstance({
    isDev: IS_DEV
  });

  verifyEmailToastsInstance.initializeToasts();
};

export const useShowEmailVerificationWarning = () => {
  const [consentModalShown, setConsentModalShown] = useState(false);
  const {
    data: {
      [FEATURE_FLAGS.accountsEmailVerification]: accountsEmailVerification
    }
  } = useAmplitudeFlags();

  useEffect(() => {
    // These cookies are responsible for whether to show the modal with consent or not
    // I couldn't find a better solution to check if the consent modal is displayed
    const areCookiesSet =
      !!getCookie(PRIVACI_COOKIE_CONSENT_GENERATED) &&
      !!getCookie(PRIVACI_COOKIE_CONSENTS) &&
      !!getCookie(PRIVACI_COOKIE_CONSENT_UUID);

    if (accountsEmailVerification && areCookiesSet) {
      showEmailVerificationWarning();
    }
  }, [accountsEmailVerification]);

  useEffect(() => {
    const onShowConsentModal = (e) => {
      if (e.data?.message?.includes('consent_given')) {
        const acceptConsentButton = document.querySelector('.cc-allow');

        if (acceptConsentButton) setConsentModalShown(true);
      }
    };

    window.addEventListener('message', onShowConsentModal);

    return () => {
      window.removeEventListener('message', onShowConsentModal);
    };
  }, []);

  useEffect(() => {
    if (!consentModalShown || !accountsEmailVerification) return;

    const acceptConsentButton = document.querySelector('.cc-allow');

    if (!acceptConsentButton) return;

    acceptConsentButton.addEventListener('click', showEmailVerificationWarning);

    // eslint-disable-next-line consistent-return
    return () => {
      acceptConsentButton.removeEventListener(
        'click',
        showEmailVerificationWarning
      );
    };
  }, [accountsEmailVerification, consentModalShown]);
};
