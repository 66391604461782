import type { ExperimentClient } from '@amplitude/experiment-js-client';
import { Experiment } from '@amplitude/experiment-js-client';
import { ANONYMOUS_USER_ID } from 'lib/constants';

import { parseFeatureFlags } from './helpers';
import { AMPLITUDE_FLAGS_KEYS } from './constants';

const amplitudeExperimentApiKey =
  process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_API_KEY;

let experiment: ExperimentClient;

export const fetchFeatureFlags = async (uuid: string) => {
  if (!experiment) {
    experiment = Experiment.initialize(amplitudeExperimentApiKey);
  }

  await experiment.start();
  await experiment.fetch(
    { user_id: uuid || ANONYMOUS_USER_ID },
    {
      flagKeys: AMPLITUDE_FLAGS_KEYS
    }
  );

  const variants = experiment.all();

  return parseFeatureFlags(variants);
};
