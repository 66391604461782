import { ExtendedRawUserProfile } from 'hooks/useIdentifyAnalytics/types';
import { MEMBERSHIP_TYPES } from 'lib/constants';
import { getUserMembership } from 'utils/getUserMembership';
import type { PianoUser } from 'types/piano';

import type { UserData, UserMainProfile, UserProfile } from './types';

// Implementation based on https://github.com/pocketoutdoormedia/activity-feed/blob/56b4a3cf872874d7281a919d6e8f15eee64ec627/src/components/03-organism/header/utils/mappers.ts
export const profileMapFactory = (
  RIVTProfile: ExtendedRawUserProfile,
  pianoUser: PianoUser
): UserData['profileMap'] => {
  const isOutsidePlus =
    getUserMembership(pianoUser.roles) === MEMBERSHIP_TYPES.Oplus;

  const mainProfileHasName = RIVTProfile.first_name && RIVTProfile.last_name;
  const mainProfileDisplayname = mainProfileHasName
    ? RIVTProfile.first_name.concat(' ').concat(RIVTProfile.last_name)
    : RIVTProfile.username;

  const mainProfile: UserMainProfile = {
    avatarURL: RIVTProfile.avatar_url,
    displayName: mainProfileDisplayname,
    // todo Enable email verification feature in header https://linear.app/outside/issue/UA-1481/[activity-feed]-enable-email-verification-feature-in-header
    // isEmailVerified: pianoUser.email_verified,
    isEmailVerified: true,
    isOutsidePlus,
    username: RIVTProfile.username,
    uuid: RIVTProfile.uuid
  };

  /* Profile switching is not fully implemented yet, so we don't want to show other user's profiles for now, just the main one */
  // const linkedSocialProfilesDTO = RIVTProfile.social_profiles ?? [];

  // const otherProfiles: UserProfile[] = linkedSocialProfilesDTO.map(
  //   (profile) => ({
  //     avatarURL: profile.avatar_url,
  //     displayName: profile.display_name || profile.username, // Default to username for profiles that might be missing a displayname
  //     username: profile.username,
  //     uuid: profile.uuid
  //   })
  // );
  // Map to key-value Iterable for Map constructor
  const profiles: [string, UserProfile][] = [
    mainProfile
    // ...otherProfiles
  ].map((profile) => [profile.uuid, profile]);

  return new Map(profiles);
};
