import HeaderLogo from 'components/01-atoms/HeaderLogo';
import dynamic from 'next/dynamic';
import { useOutsidePackagesUserData } from 'helpers/outside-packages/useOutsidePackagesUserData';

const HeaderWC = dynamic(() => import('./HeaderWC'), { ssr: false });

const Header = () => {
  const { isFetching, userData } = useOutsidePackagesUserData();

  const renderContent = () =>
    !isFetching ? (
      <HeaderWC userData={userData}>
        <HeaderLogo />
      </HeaderWC>
    ) : null;

  return (
    <div className="sticky top-0 z-30 h-[var(--outside-header-height)] w-full bg-foreground-default">
      {renderContent()}
    </div>
  );
};

export default Header;
