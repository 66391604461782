import { createContext } from 'react';

import type { IContext } from '../types';

/**
 * Interface for manipulation of context and event triggering.
 * Implementation is defined in TrackingProvider. `_data` is intended for internal
 * use only and should be considered private.
 */
const context: IContext = {
  _data: {
    // Intended for private use only
    eventPayload: {},
    eventOptions: {},
    payload: {},
    options: {},
    // The original trigger implementation passed in to TrackingProvider
    trigger: null
  },
  // Used to determine if a TrackingProvider exists in the context chain.
  hasProvider: false,
  // The trigger function passed in to TrackingProvider wrapped with data merging.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  trigger: () => {}
};

// createContext will return an object with Provider and Consumer members.
export const TrackingContext = createContext(context);
