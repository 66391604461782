import type { FeatureFlags } from 'lib/amplitude/types';

// The values of this object must correspond to real Amplitude flag keys
export const FEATURE_FLAGS = {
  accountsEmailVerification: 'accounts-email-verification'
} as const;

export const DEFAULT_FLAGS: FeatureFlags = Object.values(FEATURE_FLAGS).reduce(
  (acc, flag) => ({
    ...acc,
    [flag]: true
  }),
  {} as FeatureFlags
);

// This ensures us that we only fetch the flags that we are using in the app
export const AMPLITUDE_FLAGS_KEYS = Object.values(FEATURE_FLAGS);
