/* eslint-disable react/destructuring-assignment */
import { createContext, Dispatch } from 'react';
import { AuthState } from 'types/WebMicrofrontends';

import { DiscountData } from './discounts';

type State = {
  headerIsReady: boolean;
  discountPrice: string | null;
  stripeCustomerId: string | null;
} & AuthState;

export const initialState: State = {
  headerIsReady: false,
  isLoggedIn: false,
  rivtToken: null,
  userType: null,
  profile: null,
  membership: null,
  subscription: null,
  emailConfirmed: false,
  newsletterSubscribed: false,
  excludeFromAnalytics: false,
  tosAccepted: false,
  activated: false,
  discountCode: null,
  discountPrice: null,
  rawUserProfile: null,
  stripeCustomerId: null
};

export enum ActionType {
  SET_USER_DATA_LOADING,
  SET_USER_PROFILE,
  SET_DISCOUNT_DATA,
  SET_HEADER_IS_READY
}

export interface SetHeaderIsReady {
  type: ActionType.SET_HEADER_IS_READY;
  payload?: AuthState;
}

export const setHeaderIsReady = (): SetHeaderIsReady => ({
  type: ActionType.SET_HEADER_IS_READY
});

export interface SetUserProfile {
  type: ActionType.SET_USER_PROFILE;
  payload?: AuthState;
}

export const setUserProfile = (profile?: AuthState): SetUserProfile => ({
  type: ActionType.SET_USER_PROFILE,
  payload: profile
});

export interface SetDiscountData {
  type: ActionType.SET_DISCOUNT_DATA;
  payload: DiscountData;
}

export const setDiscountData = (
  discountData: DiscountData
): SetDiscountData => ({
  type: ActionType.SET_DISCOUNT_DATA,
  payload: discountData
});

type AuthActionType = SetHeaderIsReady | SetUserProfile | SetDiscountData;

// Reducer
export const AuthContextReducer = (state: State, action: AuthActionType) => {
  switch (action.type) {
    case ActionType.SET_HEADER_IS_READY: {
      return {
        ...state,
        headerIsReady: true
      };
    }
    case ActionType.SET_USER_PROFILE: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ActionType.SET_DISCOUNT_DATA: {
      const {
        payload: { discountCode, discountPrice }
      } = action;
      return {
        ...state,
        discountCode: discountCode || null,
        discountPrice: discountPrice || null
      };
    }

    default: {
      return state;
    }
  }
};

export const AuthContext = createContext<{
  state: State;
  dispatch: Dispatch<AuthActionType>;
}>({ state: initialState, dispatch: () => undefined });
