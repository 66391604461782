import { ExtendedRawUserProfile } from 'hooks/useIdentifyAnalytics/types';
import { getPianoCookieClient } from 'utils/getPianoCookieClient';

import { profileMapFactory } from './profileMapFactory';
import type { UserData } from './types';

export const getUserData = (
  RIVTProfile: Nullable<ExtendedRawUserProfile>
): UserData => {
  const pianoUser = getPianoCookieClient();

  if (!pianoUser || !RIVTProfile) {
    return null;
  }

  const profileMap = profileMapFactory(RIVTProfile, pianoUser);

  return {
    actingProfileID: pianoUser.uuid,
    profileMap
  };
};
