import { FC } from 'react';

import {
  AnalyticsDefaultOptions,
  AnalyticsDefaultPayload,
  AnalyticsDefaultTrigger,
  AnalyticsProvider,
  AnalyticsTrigger
} from '../AnalyticsProvider';

import { LearnFederationProvider } from './LearnFederationProvider';

type HeadlessProviderProps = {
  domain: string;
};

export const LearnHeadlessProvider: FC<HeadlessProviderProps> = ({
  children,
  domain
}) => (
  <LearnFederationProvider>
    <AnalyticsProvider
      payload={{
        ...AnalyticsDefaultPayload,
        ...{ props: { domain } }
      }}
      options={{
        ...AnalyticsDefaultOptions,
        ...{
          writeKey: process.env.NEXT_PUBLIC_METAROUTER_ID,
          analyticsJsUrl: process.env.NEXT_PUBLIC_METAROUTER_URL,
          gtmId: process.env.NEXT_PUBLIC_GTM_ID
        }
      }}
      trigger={AnalyticsDefaultTrigger}
    >
      <AnalyticsTrigger event="page">{children}</AnalyticsTrigger>
    </AnalyticsProvider>
  </LearnFederationProvider>
);
